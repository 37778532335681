<template>
  <div id="invite-bounty">
    <div id="invite-bg">
      <img src="../../assets/invite-bounty.png" />
      <!-- <div id="bounty-scroll"></div> -->
      <div id="invite-link" @click="dialogOpen">
        复制专属邀请链接 / 邀请码
      </div>
      <div id="withdraw" @click="withdraw">去提现</div>
    </div>
    <div id="results-display">
      <div class="result">
        <div class="line">
          <span class="count-label">{{ amount || '0' }}</span
          >元
        </div>
        <div class="line">累计获得赏金</div>
      </div>
      <div id="divider"></div>
      <div class="result">
        <div class="line">
          <span class="count-label">{{ personCount || '0' }}</span
          >人
        </div>
        <div class="line">累计邀请好友</div>
      </div>
    </div>
    <div id="invite-intro">
      <div id="invite-guide-img">
        <img src="../../assets/invite1.png" />
        <div class="right-arrow"></div>
        <img src="../../assets/invite2.png" />
        <div class="right-arrow"></div>
        <img src="../../assets/invite3.png" />
      </div>
      <div id="invite-guide-text">
        <div class="guide-text" style="margin-left: 0.1rem;">
          邀请链接/码<br />发给好友
        </div>
        <div class="guide-text">好友下单<br />并上车</div>
        <div class="guide-text" style="margin-right: 0.24rem;">
          双方获取 <br />奖励
        </div>
      </div>
      <!-- <div id="prompt">请您通过微信登录，再复制发送邀请链接</div> -->
      <div class="intro-text">
        1、将邀请链接/码发送给好友或者发表到社交平台上
      </div>
      <div class="intro-text">
        2、好友通过您的邀请链接下单或下单时填写你的邀请码下单并上车成功，即为邀请成功
      </div>
      <div class="intro-text">
        <span
          >3、您和好友双方都获得相应奖励，并支持<span
            @click="withdraw"
            style="color: #5ad794;cursor: pointer;"
            >可提现</span
          ></span
        >
      </div>
      <table class="invitation-tb">
        <tr>
          <th>好友下单</th>
          <th>你获得奖励</th>
          <th>好友获得奖励</th>
        </tr>
        <tr>
          <td>普通1年</td>
          <td style="color: #D70606;">1.5元</td>
          <td style="color: #D70606;">1.5元立减</td>
        </tr>
        <tr>
          <td>高级1年</td>
          <td style="color: #D70606;">2.5元</td>
          <td style="color: #D70606;">2.5元立减</td>
        </tr>
      </table>
      <div class="withdraw-tips">
        <div class="tips-label">注意</div>
        <div class="tips-text">
          1、被邀请的好必须是从未在平台下过单的新用户，否则不算邀请成功
        </div>
        <div class="tips-text">
          2、每个自然月可申请上限 1000 元，每次提现不少于 10 元
        </div>
      </div>
    </div>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>

    <div class="copy-dialog-mask" v-if="copyDialogShow">
      <div class="copy-dialog">
        <div class="tips">
          以下两种方式任选其一
        </div>
        <div class="copy-content">
          <div class="copy-title">
            邀请链接<span class="sub-info">（好友通过链接进来网站下单）</span>
          </div>
          <div class="content-box">
            {{ invitationUrl }}
            <div class="copy-btn" @click="cpoyLink(invitationUrl)">复制</div>
          </div>
        </div>
        <div class="copy-content" style="height: 1.9rem;">
          <div class="copy-title">
            邀请码<span class="sub-info">（好友下单时填写）</span>
          </div>
          <div class="content-box">
            {{ invitationCode }}
            <div class="copy-btn" @click="cpoyLink(invitationCode)">复制</div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="footer" @click="copyDialogShow = false">
          复制好了
        </div>
      </div>
    </div>
    <div class="rules" @click="rules">活动规则</div>
  </div>
</template>
<script>
import Toast from '@/components/Toast'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'
import { getInvitationInfo } from '@/api/carpool/invitaion'

export default {
  name: 'invite-bounty',
  data () {
    return {
      copyDialogShow: false,
      invitationCode: '',
      toastMsg: '',
      showToast: false,
      isWeixin: this.$isWeixin,
      invitationUrl: '',
      amount: '',
      personCount: 0
    }
  },
  components: {
    Toast
  },
  methods: {
    dialogOpen () {
      if (this.invitationCode) {
        this.copyDialogShow = true
      } else {
        this.toastMsg = '暂无邀请资格哦~请查看活动规则'
        this.showToast = true
      }
    },
    rules () {
      this.$router.push('/invitation-bounty/rules')
    },
    withdraw () {
      this.$router.push('/invitation-bounty/withdraw')
    },
    cpoyLink (value) {
      if (!this.isWeixin) {
        this.toastMsg = '请在微信打开此网页再复制分享'
        this.showToast = true
        return
      }

      let input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      input.setSelectionRange(0, input.value.length)
      document.execCommand('Copy')
      document.body.removeChild(input)
      this.toastMsg = '链接复制成功，快去邀请好友吧'
      this.showToast = true
    },
    async getInvitationInfo () {
      try {
        let res = await getInvitationInfo({
          uuid: localStorage.getItem('uuid')
        })

        if (res.data.code == 0) {
          this.invitationCode = res.data.data.invitationCode
          this.invitationUrl = this.invitationCode ? `https://carpool.yyouren.com/carpool/kuaijieche?ic=${this.invitationCode}` : null
          this.amount = res.data.data.amount
          this.personCount = res.data.data.personCount
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    }
  },
  created () {
    this.isWeixin = true
    if (!this.isWeixin) {
      this.toastMsg = '请在微信打开此网页再复制分享'
      this.showToast = true
    } else {
      this.getInvitationInfo()
    }
  }
}
</script>

<style lang="less" scoped>
#invite-bounty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  background: #f2f3e9;
  position: relative;
  #invite-bg {
    width: 7.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 7.5rem;
    }
    #bounty-scroll {
      width: 5.3rem;
      height: 0.58rem;
      background: #ffffff;
      border-radius: 0.29rem;
      border: 0.02rem solid #339886;
      position: absolute;
      top: 3.1rem;
      font-size: 0.2rem;
      color: #333333;
      display: flex;
      align-items: center;
      padding-left: 0.24rem;
    }
    #invite-link {
      width: 5.62rem;
      height: 0.9rem;
      background: linear-gradient(129deg, #fead67 0%, #ee3240 100%);
      box-shadow: 0px 0px 0.08rem 0.04rem rgba(16, 66, 57, 0.18);
      border-radius: 0.14rem;
      font-weight: bold;
      font-size: 0.36rem;
      color: #fff2d6;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 1.54rem;
      cursor: pointer;
    }
    #withdraw {
      width: 1.63rem;
      height: 0.68rem;
      background: #ebde99;
      box-shadow: 0px 0.04rem 0.1rem 0 #d4c260;
      border-radius: 0.32rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 0px 0.04rem 0.1rem #d4c260;
      font-size: 0.32rem;
      color: #2c3105;
      position: absolute;
      bottom: 0.42rem;
      cursor: pointer;
    }
  }
  #results-display {
    width: 7.02rem;
    height: 1.28rem;
    background: #ffffff;
    border-radius: 0.08rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .result {
      font-size: 0.28rem;
      height: 100%;
      width: 3.5rem;
      display: flex;
      flex-direction: column;
      color: #333333;
      align-items: center;
      justify-content: center;
      .count-label {
        color: #339886;
      }
    }
    #divider {
      width: 0.01rem;
      height: 0.6rem;
      background: #e5e5e5;
    }
  }
  #invite-intro {
    margin-top: 0.24rem;
    width: 7.02rem;
    background: #fff;
    border-radius: 0.04rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.4rem;
    margin-bottom: 0.24rem;
    #invite-guide-img {
      width: 6.06rem;
      margin-top: 0.24rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 0.9rem;
        height: 0.9rem;
      }
      .right-arrow {
        position: relative;
        height: 0px;
        width: 0px;
        border-bottom: 0.14rem solid transparent;
        border-left: 0.2rem solid #5ad794;
        border-top: 0.14rem solid transparent;
      }
    }
    #invite-guide-text {
      width: 6rem;
      margin-top: 0.12rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.24rem;
      color: #333333;
      .guide-text {
        margin-top: 0.12rem;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }
    #prompt {
      width: 5rem;
      height: 0.66rem;
      background: #f5f6f7;
      border-radius: 0.08rem;
      border: 0.02rem solid #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.24rem;
      color: #9d9d9d;
      margin-top: 0.36rem;
      margin-bottom: 0.12rem;
    }
    .intro-text {
      width: 6.54rem;
      display: flex;
      font-size: 0.28rem;
      color: #333333;
      justify-content: flex-start;
      margin-top: 0.2rem;
    }
  }
  .copy-dialog-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 7.5rem;
    background: #595959;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    .copy-dialog {
      color: #333333;
      font-size: 0.24rem;
      width: 6.44rem;
      height: 7.8rem;
      background: #ffffff;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tips {
        margin-top: 0.32rem;
        width: 5.64rem;
        height: 0.78rem;
        background: #fbf1cd;
        border-radius: 0.08rem;
        display: flex;
        align-items: center;
        padding-left: 0.24rem;
      }
      .copy-content {
        font-size: 0.28rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        margin-top: 0.46rem;
        .copy-title {
          font-weight: bold;
          margin-left: 0.3rem;
          .sub-info {
            font-weight: 400;
            color: #999999;
          }
        }
        .content-box {
          margin-left: 0.3rem;
          margin-right: 0.3rem;
          margin-top: 0.16rem;
          height: 1.7rem;
          padding-top: 0.24rem;
          padding-left: 0.24rem;
          padding-right: 0.24rem;
          background: #efefef;
          border-radius: 0.06rem;
          position: relative;
          word-wrap: break-word;
          .copy-btn {
            width: 1.36rem;
            height: 0.66rem;
            background: #50d0a9;
            border-radius: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.24rem;
            color: #ffffff;
            right: 0.12rem;
            position: absolute;
            bottom: 0.12rem;
            cursor: pointer;
          }
        }
      }
      .divider {
        width: 100%;
        height: 1px;
        background: #efefef;
        margin-top: 0.4rem;
      }
      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.1rem;
        font-size: 0.36rem;
        cursor: pointer;
        color: #69d9b6;
      }
    }
  }
  .invitation-tb {
    margin-top: 0.3rem;
    font-size: 0.28rem;
    width: 6.54rem;
    color: #333333;
    margin-left: 0.24rem;
    margin-right: 0.24rem;
    &,
    th,
    td {
      border: 0.02rem solid #efefef;
      border-collapse: collapse;
    }
    th {
      height: 0.78rem;
      width: 33%;
      background: #f5f5f5;
    }
    td {
      height: 0.78rem;
      text-align: center;
    }
  }
  .withdraw-tips {
    .tips-label {
      color: #5ad794;
    }
    .tips-text {
      margin-top: 0.12rem;
      line-height: 0.4rem;
    }
    font-size: 0.28rem;
    display: flex;
    flex-direction: column;
    width: 6.54rem;
    margin-top: 0.16rem;
  }
  .rules {
    font-size: 0.28rem;
    text-align: center;
    color: #ffffff;
    position: absolute;
    right: 0;
    width: 1.5rem;
    height: 0.64rem;
    background: #ff9e83;
    border-radius: 0.32rem 0px 0px 0.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.04rem;
    top: 5rem;
    cursor: pointer;
  }
}
</style>
